import FilterModal from "@core/filters/filter-modal.vue";
import { Vue, Component } from "vue-property-decorator";
import {
    IAccount,
    ICompany,
    IEmployee,
    ISettingsTableUpdateDto,
    IShop,
    IStore,
    ITableFilter,
    IUser,
    TableType,
} from "@lib";
import { PermissionCommonSection, PermissionRight } from "@lib";
import { Period } from "@/core/types";
import { Button, Html, Icon } from "@core/components/alt-ui/controls";
import ReportsToolbar from "./reports-toolbar/reports-toolbar.vue";
import ReportsView from "./reports-view/reports-view.vue";
import { IReportResult } from "./reports/report";
import {
    ReportFilterController,
    getReportRegistry,
    IReportRegistryItem,
    ReportType,
    IReportFilterContext,
} from "./report-filter-controller";

@Component({
    name: "reports",
    components: {
        ReportsToolbar,
        ReportsView,
        FilterModal,
    },
})
export default class Reports extends Vue {
    private user!: IUser;
    private company!: ICompany;
    private accounts: IAccount[] = [];
    private employees: IEmployee[] = [];
    private stores: IStore[] = [];
    private shops: IShop[] = [];
    private report: IReportResult | null = null;
    public tableFilter: ITableFilter[] = [];

    private reportFilterController!: ReportFilterController;

    private can = {
        read: this.$secure.checkCommon(PermissionCommonSection.Reports, PermissionRight.Read),
    };

    public get filterContext(): IReportFilterContext {
        const now = new Date();
        const currentDate = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, "0")}-${now.getDate()}`;

        return {
            accounts: this.accounts,
            employees: this.employees,
            stores: this.stores,
            shops: this.shops,
            user: this.user,
            defaultFilter: {
                type: ReportType.BaseChart,
                period: Period.Custom,
                dates: [currentDate, currentDate],
            },
            currentFilter: this.tableFilter,
        };
    }

    public constructor() {
        super();

        this.reportFilterController = new ReportFilterController();
        this.reportFilterController.onSave = this.applyFilter.bind(this);
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            this.accounts = await this.$info.getAccounts();
            this.employees = await this.$info.getEmployees();
            this.stores = await this.$info.getStores();
            this.shops = await this.$info.getShops();
            this.initHeader();
            await this.selectTableSettings();
            this.reportFilterController.init(this.filterContext);
            await this.generateReport();

            // TODO: на продакшене без этого почему-то не работает
            this.can.read = this.$secure.checkCommon(PermissionCommonSection.Reports, PermissionRight.Read);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private initHeader(): void {
        const hdrTitle = new Html();
        hdrTitle.id = "reports.header-title";
        hdrTitle.html = '<h2 class="m-0">Отчёты</h2>';

        const hdrRefresh = new Button();
        hdrRefresh.id = "reports.header-refresh";
        hdrRefresh.variant = "flat-dark";
        hdrRefresh.class = "p-0.5 mx-0.5";
        hdrRefresh.help = "Обновить";
        hdrRefresh.icon = new Icon();
        hdrRefresh.icon.icon = "RefreshCwIcon";
        hdrRefresh.addClickHandler(() => this.refreshData());

        this.$info.ui.setHeaderControls([hdrTitle, hdrRefresh]);
    }

    private async selectTableSettings(): Promise<void> {
        try {
            const service = this.$alt.system.usecase.createSettingsTableUseCase();
            const table = await service.get(this.company.id, this.user.id, TableType.Report);
            if (!table) {
                throw new Error();
            }

            this.tableFilter = table.filter ?? [];
        } catch {}
    }

    private async generateReport(): Promise<void> {
        const filter = this.reportFilterController.localFilter;
        const item = this.getReportRegistryItem(this.reportFilterController.localFilter.type ?? "");

        this.report = await item.report.generate({
            context: this,
            company: this.company.id,
            filter,
        });
    }

    private getReportRegistryItem(type: string): IReportRegistryItem {
        const info = getReportRegistry().find(tk => tk.type === type);
        if (!info) {
            throw new Error("Информация по отчёту не найдена.");
        }
        return info;
    }

    private async refreshData(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.generateReport();
            this.$alt.toast.success("Данные обновлены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private showFilterModal(): void {
        this.reportFilterController.show(this.filterContext);
    }

    private async applyFilter(filter: ITableFilter[]): Promise<boolean> {
        try {
            this.$alt.loader.show();
            const dto: ISettingsTableUpdateDto = { filter };
            const service = this.$alt.system.usecase.createSettingsTableUseCase();
            await service.update(this.company.id, this.user.id, TableType.Report, dto);
            this.tableFilter = filter;
            await this.generateReport();
            return true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return false;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private getEmployee(userId: string): IEmployee | undefined {
        return this.employees.find(e => e.userRef.id === userId);
    }
}
