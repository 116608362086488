import { ReportKind } from "../report-filter-controller";
import { IReport, IReportOptions, IReportResult } from "./report";
import { FilterPair } from "@/utils/filter";
import { Formatter } from "@/utils/formatter";

export class OrdersTableReport implements IReport {
    public async generate(options: IReportOptions): Promise<IReportResult> {
        const dates = options.filter.dates as FilterPair;

        const from = dates[0];
        const to = dates[1];

        const usecase = options.context.$alt.system.usecase.createReportUseCase();
        const report = await usecase.orders(options.company, {
            timezone: new Date().getTimezoneOffset().toString(),
            from,
            to,
        });

        const classHeaderNum = "va-td va-td-num text-center text-xl";
        const classHeaderName = "va-td text-center text-xl";
        const classHeaderMoney = "va-td va-td-money text-center text-xl";
        const classSubHeaderMoney = "va-td va-td-money text-center text-xl";

        const classCellNum = "va-td va-td-num text-center text-xl";
        const classCellName = "va-td text-nowrap text-xl";
        const classCellMoney = "va-td va-td-money text-center text-xl";

        const classFooterName = "va-td text-right text-xl font-bold va-td-noborder";
        const classFooterMoney = "va-td va-td-money text-center text-xl font-bold";

        const header: any[] = [
            {
                cells: [
                    {
                        value: "Заявка",
                        class: classHeaderName,
                        rowspan: 2,
                    },
                    {
                        value: "Выручка",
                        class: classHeaderMoney,
                        colspan: 4,
                    },
                    {
                        value: "Расходы",
                        class: classHeaderMoney,
                        colspan: 4,
                    },
                    {
                        value: "Прибыль",
                        class: classHeaderMoney,
                        rowspan: 2,
                    },
                ],
            },
            {
                cells: [
                    // выручка

                    {
                        value: "Работы",
                        class: classSubHeaderMoney,
                    },
                    {
                        value: "Материалы",
                        class: classSubHeaderMoney,
                    },
                    {
                        value: "Скидка",
                        class: classSubHeaderMoney,
                    },
                    {
                        value: "Сумма",
                        class: classSubHeaderMoney,
                    },

                    // расходы

                    {
                        value: "Работы",
                        class: classSubHeaderMoney,
                    },
                    {
                        value: "Материалы",
                        class: classSubHeaderMoney,
                    },
                    {
                        value: "Зарплата",
                        class: classSubHeaderMoney,
                    },
                    {
                        value: "Сумма",
                        class: classSubHeaderMoney,
                    },
                ],
            },
        ];

        const total = {
            count: 0,
            revenueWorks: 0,
            revenueMaterials: 0,
            revenueDiscount: 0,
            revenueSum: 0,
            expensesWorks: 0,
            expensesMaterials: 0,
            expensesSalary: 0,
            expensesSum: 0,
            profit: 0,
        };

        const body: any[] = [];
        for (const item of report) {
            total.count += 1;
            total.revenueWorks += item.revenue.works;
            total.revenueMaterials += item.revenue.materials;
            total.revenueDiscount += item.revenue.discount;
            total.revenueSum += item.revenue.sum;
            total.expensesWorks += item.expenses.works;
            total.expensesMaterials += item.expenses.materials;
            total.expensesSalary += item.expenses.salary;
            total.expensesSum += item.expenses.sum;
            total.profit += item.profit;

            body.push({
                cells: [
                    {
                        value: `Заявка #${item.order.number}`,
                        class: classCellName,
                    },

                    // выручка

                    {
                        value: Formatter.money(item.revenue.works),
                        class: classCellMoney,
                    },
                    {
                        value: Formatter.money(item.revenue.materials),
                        class: classCellMoney,
                    },
                    {
                        value: Formatter.money(item.revenue.discount),
                        class: classCellMoney,
                    },
                    {
                        value: Formatter.money(item.revenue.sum),
                        class: classCellMoney,
                    },

                    // расходы

                    {
                        value: Formatter.money(item.expenses.works),
                        class: classCellMoney,
                    },
                    {
                        value: Formatter.money(item.expenses.materials),
                        class: classCellMoney,
                    },
                    {
                        value: Formatter.money(item.expenses.salary),
                        class: classCellMoney,
                    },
                    {
                        value: Formatter.money(item.expenses.sum),
                        class: classCellMoney,
                    },

                    //

                    {
                        value: Formatter.money(item.profit),
                        class: classCellMoney,
                    },
                ],
            });
        }

        const footer: any[] = [
            {
                cells: [
                    {
                        value: "Итого:",
                        class: classFooterName,
                    },

                    // выручка

                    {
                        value: Formatter.money(total.revenueWorks),
                        class: classFooterMoney,
                    },
                    {
                        value: Formatter.money(total.revenueMaterials),
                        class: classFooterMoney,
                    },
                    {
                        value: Formatter.money(total.revenueDiscount),
                        class: classFooterMoney,
                    },
                    {
                        value: Formatter.money(total.revenueSum),
                        class: classFooterMoney,
                    },

                    // расходы

                    {
                        value: Formatter.money(total.expensesWorks),
                        class: classFooterMoney,
                    },
                    {
                        value: Formatter.money(total.expensesMaterials),
                        class: classFooterMoney,
                    },
                    {
                        value: Formatter.money(total.expensesSalary),
                        class: classFooterMoney,
                    },
                    {
                        value: Formatter.money(total.expensesSum),
                        class: classFooterMoney,
                    },

                    //

                    {
                        value: Formatter.money(total.profit),
                        class: classFooterMoney,
                    },
                ],
            },
        ];

        const format = "LL";
        const subtitle =
            from === to
                ? Formatter.datetime(from, { format })
                : `${Formatter.datetime(from, { format })} - ${Formatter.datetime(to, { format })}`;

        return {
            title: "Отчёт по заявкам",
            subtitle: subtitle,
            items: [
                {
                    kind: ReportKind.Table,
                    table: {
                        header,
                        body,
                        footer,
                    },
                },
            ],
        };
    }

    private dtFormat(date: number | string | Date): string {
        return Formatter.datetime(date, { format: "MMM 'YY" });
    }
}
