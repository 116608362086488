import { ISaleGoodsReportItemDto, IShop } from "@lib";
import { ReportKind } from "../report-filter-controller";
import { IReport, IReportOptions, IReportResult } from "./report";
import { FilterPair } from "@/utils/filter";
import { Formatter } from "@/utils/formatter";
import { AppException } from "@/core/exceptions";

export class SaleGoodsTableReport implements IReport {
    public async generate(options: IReportOptions): Promise<IReportResult> {
        const dates = options.filter.dates as FilterPair;
        const shops = options.filter.shops as string[];

        if (!shops) {
            throw new AppException("Необходимо выбрать магазин.");
        }

        const from = dates[0];
        const to = dates[1];

        const shopNames = [];
        for (const shopId of shops) {
            const shop = ((options.context as any).shops as IShop[]).find(s => s.id === shopId);
            if (shop) {
                shopNames.push(shop.info.name);
            }
        }

        const usecase = options.context.$alt.system.usecase.createReportUseCase();
        const report = await usecase.saleGoods(options.company, {
            timezone: new Date().getTimezoneOffset().toString(),
            from,
            to,
            shops,
        });

        const classHeaderName = "va-td text-center text-xl";
        const classHeaderNum = "va-td va-td-money text-center text-xl";
        const classHeaderMoney = "va-td va-td-num text-center text-xl";

        const classFooterEmpty = "va-td text-xl font-bold va-td-noborder";
        const classFooterName = "va-td text-right text-xl font-bold  va-td-noborder";
        const classFooterNum = "va-td va-td-num text-center text-xl font-bold";
        const classFooterMoney = "va-td va-td-money text-center text-xl font-bold";

        const header: any[] = [
            {
                cells: [
                    {
                        value: "№",
                        class: classHeaderName,
                    },
                    {
                        value: "Наименование",
                        class: classHeaderName,
                    },
                    {
                        value: "Количество",
                        class: classHeaderNum,
                    },
                    {
                        value: "Расходы",
                        class: classHeaderMoney,
                    },
                    {
                        value: "Выручка",
                        class: classHeaderMoney,
                    },
                    {
                        value: "Прибыль",
                        class: classHeaderMoney,
                    },
                ],
            },
        ];

        const body: any[] = [];
        for (const item of report.items) {
            this.addBodyRow(item, body);
        }

        const total = report.total;
        const totalExpensesStr = Formatter.money(total.expenses);
        const totalRevenueStr = Formatter.money(total.revenue);
        const totalProfitStr = Formatter.money(total.revenue - total.expenses);

        const footer: any[] = [
            {
                cells: [
                    {
                        value: "",
                        class: classFooterEmpty,
                    },
                    {
                        value: "Итого:",
                        class: classFooterName,
                    },
                    {
                        value: total.quantity,
                        class: classFooterNum,
                    },
                    {
                        value: totalExpensesStr,
                        class: classFooterMoney,
                    },
                    {
                        value: totalRevenueStr,
                        class: classFooterMoney,
                    },
                    {
                        value: totalProfitStr,
                        class: classFooterMoney,
                    },
                ],
            },
        ];

        const format = "LL";
        const subtitle2 =
            from === to
                ? Formatter.datetime(from, { format })
                : `${Formatter.datetime(from, { format })} - ${Formatter.datetime(to, { format })}`;

        return {
            title: "Отчёт по проданным товарам",
            subtitle: shopNames.join(", "),
            subtitle2: subtitle2,
            items: [
                {
                    kind: ReportKind.Table,
                    table: {
                        header,
                        body,
                        footer,
                    },
                },
            ],
        };
    }

    private addBodyRow(item: ISaleGoodsReportItemDto, body: any[]): void {
        const classCellName = "va-td text-xl";
        const classCellNum = "va-td va-td-num text-center text-xl";
        const classCellMoney = "va-td va-td-money text-center text-xl";

        const expensesStr = Formatter.money(item.expenses);
        const revenueStr = Formatter.money(item.revenue);
        const profitStr = Formatter.money(item.revenue - item.expenses);

        body.push({
            cells: [
                {
                    value: body.length + 1,
                    class: classCellNum,
                },
                {
                    value: item.name,
                    class: classCellName,
                },
                {
                    value: item.quantity,
                    class: classCellNum,
                },
                {
                    value: expensesStr,
                    class: classCellMoney,
                },
                {
                    value: revenueStr,
                    class: classCellMoney,
                },
                {
                    value: profitStr,
                    class: classCellMoney,
                },
            ],
        });
    }
}
