import { IGoodCategoryInfo, IStore } from "@lib";
import { ReportKind } from "../report-filter-controller";
import { IReport, IReportOptions, IReportResult } from "./report";
import { FilterPair } from "@/utils/filter";
import { Formatter } from "@/utils/formatter";
import { AppException } from "@/core/exceptions";

export class StoreTableReport implements IReport {
    public async generate(options: IReportOptions): Promise<IReportResult> {
        const dates = options.filter.dates as FilterPair;
        const stores = options.filter.stores as string[];

        if (!stores) {
            throw new AppException("Необходимо выбрать склад.");
        }

        const from = dates[0];
        const to = dates[1];

        const storeNames = [];
        for (const storeId of stores) {
            const store = ((options.context as any).stores as IStore[]).find(s => s.id === storeId);
            if (store) {
                storeNames.push(store.info.name);
            }
        }

        const usecase = options.context.$alt.system.usecase.createReportUseCase();
        const report = await usecase.stores(options.company, { stores });

        const classHeaderName = "va-td text-center text-xl";
        const classHeaderMoney = "va-td va-td-money text-center text-xl";

        const classFooterEmpty = "va-td text-xl font-bold va-td-noborder";
        const classFooterName = "va-td text-right text-xl font-bold  va-td-noborder";
        const classFooterNum = "va-td va-td-num text-center text-xl font-bold";
        const classFooterMoney = "va-td va-td-money text-center text-xl font-bold";

        const header: any[] = [
            {
                cells: [
                    {
                        value: "№",
                        class: classHeaderName,
                    },
                    {
                        value: "Категория",
                        class: classHeaderName,
                    },
                    {
                        value: "Наименований",
                        class: classHeaderName,
                    },
                    {
                        value: "Количество",
                        class: classHeaderName,
                    },
                    {
                        value: "Себестоимость",
                        class: classHeaderMoney,
                    },
                    {
                        value: "Сумма продажи",
                        class: classHeaderMoney,
                    },
                    {
                        value: "Прибыль",
                        class: classHeaderMoney,
                    },
                ],
            },
        ];

        const body: any[] = [];
        for (const key in report) {
            if (key === "total" || key === "noCategory") {
                continue;
            }

            this.addBodyRow(report[key], body);
        }
        this.addBodyRow(report.noCategory, body);

        const total = report.total;
        const totalExpensesStr = Formatter.money(total.expenses);
        const totalRevenueStr = Formatter.money(total.revenue);
        const totalProfitStr = Formatter.money(total.revenue - total.expenses);

        const footer: any[] = [
            {
                cells: [
                    {
                        value: "",
                        class: classFooterEmpty,
                    },
                    {
                        value: "Всего:",
                        class: classFooterName,
                    },
                    {
                        value: total.count,
                        class: classFooterNum,
                    },
                    {
                        value: total.quantity,
                        class: classFooterNum,
                    },
                    {
                        value: totalExpensesStr,
                        class: classFooterMoney,
                    },
                    {
                        value: totalRevenueStr,
                        class: classFooterMoney,
                    },
                    {
                        value: totalProfitStr,
                        class: classFooterMoney,
                    },
                ],
            },
        ];

        const format = "LL";
        const subtitle2 =
            from === to
                ? Formatter.datetime(from, { format })
                : `${Formatter.datetime(from, { format })} - ${Formatter.datetime(to, { format })}`;

        return {
            title: "Отчёт по складам",
            subtitle: storeNames.join(", "),
            subtitle2: subtitle2,
            items: [
                {
                    kind: ReportKind.Table,
                    table: {
                        header,
                        body,
                        footer,
                    },
                },
            ],
        };
    }

    private addBodyRow(category: IGoodCategoryInfo, body: any[]): void {
        const classCellName = "va-td text-nowrap text-xl";
        const classCellNum = "va-td va-td-num text-center text-xl";
        const classCellMoney = "va-td va-td-money text-center text-xl";

        const expensesStr = Formatter.money(category.expenses);
        const revenueStr = Formatter.money(category.revenue);
        const profitStr = Formatter.money(category.revenue - category.expenses);

        body.push({
            cells: [
                {
                    value: body.length + 1,
                    class: classCellNum,
                },
                {
                    value: category.category ? category.category.name : "Без категории",
                    class: classCellName,
                },
                {
                    value: category.count,
                    class: classCellNum,
                },
                {
                    value: category.quantity,
                    class: classCellNum,
                },
                {
                    value: expensesStr,
                    class: classCellMoney,
                },
                {
                    value: revenueStr,
                    class: classCellMoney,
                },
                {
                    value: profitStr,
                    class: classCellMoney,
                },
            ],
        });
    }
}
